module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PR735ZD","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"clearout-gatsby-website","short_name":"clearout-website","start_url":"/","background_color":"#ffa700","theme_color":"#ffa700","display":"standalone","icon":"src/images/icons/clearout-icon-600x600.png","icons":[{"src":"fav-icon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"fav-icon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"fav-icon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"fav-icon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"fav-icon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"fav-icon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"fav-icon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"fav-icon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c37791c52e2536f63f1e32d8d245744e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"namespace":"clearout","displayName":false,"fileName":false,"minify":true,"transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://test.clearout.io","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
